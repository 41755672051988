export const Bio = {
  name: "Ivan on Tech",
  roles: [
    "Gold Expert",
    "Real Estate Advisor",
    "Precious Stones Master",
    "Stock Master",
    "Crypto Master"
  ],
  description:
    "This channel is all about cryptocurrencies and the blockchain technology Collaborations and Business: https://jzp67fxmgab.typeform.com/to/JwuKL0Oe OBS! I never reach out on Telegram with business proposals! If you've been contacted by me on Telegram - A SCAMMER IS TRYING TO ROB YOU! My Telegram handle is @ivanontech and I never use it for business requests. All other handles are scammers, don't trust anyone. Sometimes scammers spoof our emails and send emails that look like they are from us, never trust anyone who wrote to you on Telegram. Scammer sometimes send photoshopped pictures that look like my passport, never trust anyone sending my passport.",
  github: "https://www.youtube.com/@IvanOnTech",
  resume:
    "https://wa.me/12562156584",
  linkedin: "https://www.linkedin.com/",
  twitter: "https://twitter.com/",
  insta: "https://www.instagram.com/",
  facebook: "https://www.facebook.com/",
};

export const skills = [
  
];

export const experiences = [

];

export const education = [
  {
    id: 0,
    img: "https://firebasestorage.googleapis.com/v0/b/fileupload-74efb.appspot.com/o/check-mark-2.png?alt=media&token=503b3696-1b13-4711-bb3d-bb635708cd83&_gl=1*1s41gsx*_ga*NzE2MTQ4ODUuMTY5NjU4NTkxMQ..*_ga_CW55HF8NVT*MTY5NjU4NTkxMS4xLjEuMTY5NjU4NjQ5Mi4zNy4wLjA.",
    school: "Stock Trading",
    date: "",
    grade: "100%",
    desc: "Silver Stocks Rick Rule",
    degree: "Gold stocks, Gold mining, Investing, precious metals, Silver Mines, Silver Mining",
  },
  {
    id: 1,
    img: "https://firebasestorage.googleapis.com/v0/b/fileupload-74efb.appspot.com/o/checkmark.png?alt=media&token=edeaba8b-b5f3-4af2-b9cf-31960e582c46&_gl=1*1lebb22*_ga*NzE2MTQ4ODUuMTY5NjU4NTkxMQ..*_ga_CW55HF8NVT*MTY5NjU4NTkxMS4xLjEuMTY5NjU4NjUyNy4yLjAuMA..",
    school: "Inflation, hyperinflation - Silver stocks to buyock Trading",
    date: "",
    grade: "100%",
    desc: "Silver stocks US",
    degree: "Gold Silver stocks",
  },
  {
    id: 2,
    img: "https://firebasestorage.googleapis.com/v0/b/fileupload-74efb.appspot.com/o/check-mark.png?alt=media&token=ea60f442-b3f4-4b44-b71b-30ea3db7c3a5&_gl=1*1dibh35*_ga*NzE2MTQ4ODUuMTY5NjU4NTkxMQ..*_ga_CW55HF8NVT*MTY5NjU4NTkxMS4xLjEuMTY5NjU4NjU2OS4yMC4wLjA.",
    school: "Investing gold coins - Uranium, uranium stocks, uranium stocks to buy",
    date: "",
    grade: "100%",
    desc: "Gold Rush",
    degree: "Gold investing for beginners & Precious metals investing",
  },
];

export const projects = [
  
  {
    id: 5,
    title: "BITCOIN BIG REJECTION!! I AM STILL BULLISH",
    date: "Oct 2, 2023",
    description:
      " *Disclaimer* Please be advised that I own a diverse portfolio of cryptocurrency as I wish to remain transparent and impartial to the cryptocurrency community at all times, and therefore, the content of my media are intended FOR GENERAL INFORMATION PURPOSES not financial advice. The information contained herein is for informational purposes only. Nothing herein shall be construed to be financial legal or tax advice. The content of this video is solely the opinions of the speaker who is not a licensed financial advisor or registered investment advisor.  Purchasing cryptocurrencies poses considerable risk of loss. The speaker does not guarantee any particular outcome. Past performance does not indicate future results This information is what was found publicly on the internet. This is all my own opinion. All information is meant for public awareness and is public domain. Please take this information and do your own research. bitcoin, cryptocurrency, crypto, altcoin, altcoin daily, blockchain, decentralized, news, best investment, top altcoins, ethereum, tron, stellar, binance, cardano,  litecoin, 2019, 2020, crash, bull run, bottom, crash, tether, bitfinex, rally, tone vays, ivan on tech, chico, video, youtube, macro, price, prediction, podcast, interview, trump, finance, stock, investment, halving, halvening, too late, when, fed, federal reserve, interest rates, rates, cut, economy, stock market, Good Morning Crypto",
    image:
      "https://raw.githubusercontent.com/lilacollier7/black/main/src/images/mq1.webp",
    tags: ["Money", "Savings", "Investment", "Bitcoin"],
    category: "Cryptocurrency",
    github: "https://www.youtube.com/@IvanOnTech",
    webapp: "https://www.youtube.com/watch?v=WIt9BxWUSCE",
  },
  {
    id: 6,
    title: "I AM BUYING ALL BITCOIN I CAN GET NOW!! (uptober tomorrow)",
    date: "Sep 30, 2023",
    description:
      "*Disclaimer* Please be advised that I own a diverse portfolio of cryptocurrency as I wish to remain transparent and impartial to the cryptocurrency community at all times, and therefore, the content of my media are intended FOR GENERAL INFORMATION PURPOSES not financial advice. The information contained herein is for informational purposes only. Nothing herein shall be construed to be financial legal or tax advice. The content of this video is solely the opinions of the speaker who is not a licensed financial advisor or registered investment advisor.  Purchasing cryptocurrencies poses considerable risk of loss. The speaker does not guarantee any particular outcome. Past performance does not indicate future results. This information is what was found publicly on the internet. This is all my own opinion. All information is meant for public awareness and is public domain. Please take this information and do your own research. bitcoin, cryptocurrency, crypto, altcoin, altcoin daily, blockchain, decentralized, news, best investment, top altcoins, ethereum, tron, stellar, binance, cardano,  litecoin, 2019, 2020, crash, bull run, bottom, crash, tether, bitfinex, rally, tone vays, ivan on tech, chico, video, youtube, macro, price, prediction, podcast, interview, trump, finance, stock, investment, halving, halvening, too late, when, fed, federal reserve, interest rates, rates, cut, economy, stock market, Good Morning Crypto Ivan on Tech by Ivan Liljeqvist",
    image:
      "https://raw.githubusercontent.com/lilacollier7/black/main/src/images/mq2.webp",
    tags: ["Government", "Stock", "Rates", "Bulls"],
    category: "BTC",
    github: "https://www.youtube.com/@IvanOnTech",
    webapp: "https://www.youtube.com/watch?v=PIXke-Rj7hc",
  },
  {
    id: 7,
    title: "RADIX BABYLON, SCRYPTO, WALLET, RADIX ENGINE AND MUCH MORE...",
    date: "Sep 26, 2023",
    description:
      "*Disclaimer* Please be advised that I own a diverse portfolio of cryptocurrency as I wish to remain transparent and impartial to the cryptocurrency community at all times, and therefore, the content of my media are intended FOR GENERAL INFORMATION PURPOSES not financial advice. The information contained herein is for informational purposes only. Nothing herein shall be construed to be financial legal or tax advice. The content of this video is solely the opinions of the speaker who is not a licensed financial advisor or registered investment advisor.  Purchasing cryptocurrencies poses considerable risk of loss. The speaker does not guarantee any particular outcome. Past performance does not indicate future results. This information is what was found publicly on the internet. This is all my own opinion. All information is meant for public awareness and is public domain. Please take this information and do your own research. bitcoin, cryptocurrency, crypto, altcoin, altcoin daily, blockchain, decentralized, news, best investment, top altcoins, ethereum, tron, stellar, binance, cardano,  litecoin, 2019, 2020, crash, bull run, bottom, crash, tether, bitfinex, rally, tone vays, ivan on tech, chico, video, youtube, macro, price, prediction, podcast, interview, trump, finance, stock, investment, halving, halvening, too late, when, fed, federal reserve, interest rates, rates, cut, economy, stock market, Good Morning Crypto Ivan on Tech by Ivan Liljeqvist",
    image:
      "https://raw.githubusercontent.com/lilacollier7/black/main/src/images/mq3.webp",
    tags: ["property", "Lending", "Blockchain", "Crypto"],
    category: "Bitcoin",
    github: "https://www.youtube.com/@IvanOnTech",
    webapp: "https://www.youtube.com/watch?v=bJx7_1rWC6U",
  },
  
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
